import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const HeroThankYou = (props) => {
    const HeroThankYouImage = useStaticQuery(graphql`
      query HeroThankYouImage {
        hero: file(absolutePath: {regex: "/assets/images/ThanksVideoCall.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2010) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Row className="sp-5">
        <Col lg="6" className="mobile-sp-5">
          <Image sizes={HeroThankYouImage.hero.childImageSharp.sizes} alt="We're excited to hear from you!"/>
        </Col>
        <Col lg="6">
            <h1>We're excited to hear from you!</h1>
            <p>Thanks so much for filing out our contact form. One of 
                our team members will get back to you by the end of the next
                business day to hear what success looks like your project
            </p>
        </Col>
      </Row>
    )
  }


export default HeroThankYou